import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

function Create() {

    const [values, setValues] = useState({
        name: "",
        email: ""
    })

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        
        axios.post('https://crud-server-cust.vercel.app/customer/', values)
        .then(res => 
        {console.log(res)
        navigate('/')}
        )
        .catch(err => console.log(err))
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Submitted Successfuly",
            showConfirmButton: false,
            timer: 1500
          });
    }

  return (
    <div className='d-flex vh-100 bg-primary justify-content-center align-items-center'>
        <div className='w-50 bg-white rounded p-3'>
            <form onSubmit={handleSubmit}>
                <h2>Add Customer</h2>

                <div className='mb-2'>
                    <label htmlFor=''>Name</label>
                    <input type='text' placeholder='name' className='form-control'
                    onChange={(e) => setValues({...values, name: e.target.value})}
                    ></input>
                </div>

                <div className='mb-2'>
                    <label htmlFor=''>Email</label>
                    <input type='text' placeholder='email' className='form-control'
                    onChange={(e) => setValues({...values, email: e.target.value})}
                    ></input>
                </div>

                <button className='btn btn-success'>Submit</button>
            </form>
        </div>
      
    </div>
  )
}

export default Create
